// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
    blog: getIcon('ic_blog'),
};

const navConfig = [
    {
        subheader: 'سامانه یکپارچه آیریک',
        items: [
            {
                title: 'مدیریت کاربران',
                path: PATH_DASHBOARD.user.list,
                icon: ICONS.blog,
            },
            {
                title: 'استعلام ها',
                path: PATH_DASHBOARD.inquiries.root,
                icon: ICONS.blog,
                children: [
                    {
                        title: 'لیست استعلام ها',
                        path: PATH_DASHBOARD.inquiries.root,
                    },
                    {
                        title: 'استعلام های جاری',
                        path: '/inquiries/list?fieldStatus=1,2,3,4,5',
                        externalLink: true,
                    },
                    {
                        title: 'استعلام های جدید',
                        path: '/inquiries/list?fieldStatus=1',
                        externalLink: true,
                    },
                    {
                        title: 'استعلام های واحد فروش',
                        path: '/inquiries/list?fieldStatus=2',
                        externalLink: true,
                    },
                    {
                        title: 'استعلام های واحد فنی',
                        path: '/inquiries/list?fieldStatus=3',
                        externalLink: true,
                    },
                    {
                        title: 'استعلام های واحد ساخت',
                        path: '/inquiries/list?fieldStatus=4',
                        externalLink: true,
                    },
                    {
                        title: 'استعلام های آماده اعلام قیمت',
                        path: '/inquiries/list?fieldStatus=5',
                        externalLink: true,
                    },
                ],
            },
            { title: 'شرکت ها', path: PATH_DASHBOARD.companies.root, icon: ICONS.blog },
            { title: 'مخاطب ها', path: PATH_DASHBOARD.experts.root, icon: ICONS.blog },
            {
                title: 'سفارش ها',
                path: PATH_DASHBOARD.orders.root,
                icon: ICONS.blog,
                children: [
                    {
                        title: 'همه سفارش ها',
                        path: PATH_DASHBOARD.orders.root,
                    },
                    {
                        title: 'سفارش های جاری',
                        path: '/orders/list?fieldStatus=9',
                        externalLink: true,
                    },
                    {
                        title: 'سفارش های خاتمه یافته',
                        path: '/orders/list?fieldStatus=11',
                        externalLink: true,
                    },
                ],
            },
            { title: 'نامه ها', path: PATH_DASHBOARD.letters.root, icon: ICONS.blog },
            { title: 'پیامک ها', path: PATH_DASHBOARD.sms.root, icon: ICONS.blog },
        ],
    },
];

export default navConfig;
