import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import {
    Box,
    List,
    Link,
    Badge,
    Button,
    Tooltip,
    Divider,
    Typography,
    ListItemText,
    ListItem,
} from '@mui/material';
import axios from '../../../utils/axios';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';



export default function NotificationsPopover() {
    const [notifications, setNotifications] = useState([]);
    const [totalUnRead, setTotalUnRead] = useState(0);
    const [open, setOpen] = useState(null);

    const fetchData = async () => axios.get('/api/v1/notifications/authUser');

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleMarkAllAsRead = async () => {
        const readAll = await axios.post('/api/v1/notifications/readall')
        fetchData().then((data) => {
            setNotifications(data.data.notifications)
            setTotalUnRead(data.data.count)
        })
    };
    const notificationClickHandler = async (notifId) => {
        const readNotif = await axios.patch(`/api/v1/notifications/${notifId}`)
        fetchData().then((data) => {
            setNotifications(data.data.notifications)
            setTotalUnRead(data.data.count)
        })
    }

    useEffect(() => {
        fetchData().then((data) => {
            setNotifications(data.data.notifications)
            setTotalUnRead(data.data.count)
        })
    }, [])



    return (
        <>
            <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify icon="eva:bell-fill" width={20} height={20} />
                </Badge>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">اعلان ها</Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            شما {totalUnRead} اعلان خوانده نشده دارید
                        </Typography>
                    </Box>

                    {totalUnRead > 0 && (
                        <Tooltip title=" همه خوانده شد">
                            <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                                <Iconify icon="eva:done-all-fill" width={20} height={20} />
                            </IconButtonAnimate>
                        </Tooltip>
                    )}
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Scrollbar sx={{ height: 360 }}>
                    <List sx={{ height: 360, overFlowY: 'auto' }}>
                        {notifications.map((notification) => (
                            <NotificationItem key={notification.id} notification={notification} setReadNotification={notificationClickHandler} />
                        ))}
                    </List>
                </Scrollbar>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Box sx={{ p: 1 }}>
                    <Link href='/notifications/list'>
                        <Button fullWidth disableRipple >
                            نمایش همه
                        </Button>
                    </Link>
                </Box>
            </MenuPopover>
        </>
    );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        // createdAt: PropTypes.instanceOf(Date),
        id: PropTypes.number,
        isRead: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
    }),
};

function NotificationItem({ notification, setReadNotification }) {
    const { title } = renderContent(notification);

    const notificationClickHandler = async (notifId) => {
        setReadNotification(notifId)
    }

    return (
        <ListItem
            onClick={() => notificationClickHandler(notification.id)}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
                ...(notification.isRead === 0 && {
                    bgcolor: '#e8f5e9',
                }),
            }}
        >
            <ListItemText
                primary={title}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            color: 'text.disabled',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                            {notification.createdAt}
                        </Box>
                        {!notification.isRead && (
                            <Box
                                onClick={(e) => notificationClickHandler(notification.id)}
                                sx={{ color: 'blue', cursor: 'pointer' }}
                            >
                                <a>خواندم</a>
                            </Box>
                        )}
                    </Typography>
                }
            />
        </ListItem>
    );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
    const title = (
        <Typography variant="subtitle2">
            {notification.title}
            {/* <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                &nbsp; {noCase(notification.description)}
            </Typography> */}
        </Typography>
    );

    if (notification.type === 'change_status') {
        return {
            // avatar: (
            //     <img
            //         alt={notification.title}
            //         src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
            //     />
            // ),
            title,
        };
    }
    return {
        title,
    };
}
