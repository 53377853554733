import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// components
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    const { user } = useAuth();

    /* This code is defining the routes for the application using the `useRoutes` hook from the
    `react-router-dom` library. It defines the routes for both the authentication and dashboard
    sections of the application, as well as a catch-all route for any other paths that are not
    defined. */
    return useRoutes([
        // Auth
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
            ],
        },

        // Dashboard Routes
        {
            path: '/',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to="/app" replace />, index: true },
                {
                    path: 'app',
                    element: <App />,
                },
                {
                    path: 'user',
                    children: [
                        { element: <Navigate to="/user/profile" replace />, index: true },
                        { path: 'profile', element: <UserProfile /> },
                        { path: 'list', element: <UserList /> },
                        { path: 'new', element: <UserCreate /> },
                        { path: 'edit/:id', element: <UserCreate /> },
                    ],
                },
                {
                    path: 'inquiries',
                    children: [
                        { element: <Navigate to="/inquiries/list" replace />, index: true },
                        { path: 'list', element: <Inquiries /> },
                        { path: 'new', element: <InquiryCreate /> },
                        { path: ':id/edit', element: <InquiryEdit /> },
                        { path: ':id/details', element: <InquiryDetails /> },
                    ],
                },
                {
                    path: 'companies',
                    children: [
                        { element: <Navigate to="/companies/list" replace />, index: true },
                        { path: 'list', element: <Companies /> },
                        { path: ':id/details', element: <CompanyDetails /> },
                        { path: 'new', element: <CompanyCreate /> },
                        { path: ':id/edit', element: <CompanyEdit /> },
                    ],
                },
                {
                    path: 'experts',
                    children: [
                        { element: <Navigate to="/experts/list" replace />, index: true },
                        { path: 'list', element: <Experts /> },
                        { path: ':id/details', element: <ExpertDetails /> },
                        { path: 'new', element: <ExpertCreate /> },
                        { path: ':id/edit', element: <ExpertEdit /> },
                    ],
                },
                {
                    path: 'orders',
                    children: [
                        { element: <Navigate to="/orders/list" replace />, index: true },
                        { path: 'list', element: <Orders /> },
                    ],
                },
                {
                    path: 'letters',
                    children: [
                        { element: <Navigate to="/letters/list" replace />, index: true },
                        { path: 'list', element: <Letters /> },
                        { path: 'new', element: <LetterCreate /> },
                        { path: ':id/details', element: <LetterDetails /> },
                        { path: ':id/edit', element: <LetterEdit /> },
                    ],
                },
                {
                    path: 'sms',
                    children: [
                        { element: <Navigate to="/sms/list" replace />, index: true },
                        { path: 'list', element: <Sms /> },
                        { path: ':id/details', element: <SmsDetails /> },
                        { path: ':id/edit', element: <SmsEdit /> },
                    ],
                },
                {
                    path: 'notifications',
                    children: [
                        { element: <Navigate to="/notifications/list" replace />, index: true },
                        { path: 'list', element: <NotificationsList /> },
                    ]
                }
            ],
        },

        // 404
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// Dashboard
const App = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const Inquiries = Loadable(lazy(() => import('../pages/dashboard/Inquiries')));
const InquiryCreate = Loadable(lazy(() => import('../pages/dashboard/InquiryCreate')));
const InquiryEdit = Loadable(lazy(() => import('../pages/dashboard/InquiryEdit')));
const InquiryDetails = Loadable(lazy(() => import('../pages/dashboard/InquiryDetails')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccess = Loadable(lazy(() => import('../pages/dashboard/UserAccess')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const Companies = Loadable(lazy(() => import('../pages/dashboard/Companies')));
const CompanyDetails = Loadable(lazy(() => import('../pages/dashboard/CompanyDetails')));
const CompanyCreate = Loadable(lazy(() => import('../pages/dashboard/CompanyCreate')));
const CompanyEdit = Loadable(lazy(() => import('../pages/dashboard/CompanyEdit')));
const Experts = Loadable(lazy(() => import('../pages/dashboard/Experts')));
const ExpertDetails = Loadable(lazy(() => import('../pages/dashboard/ExpertDetails')));
const ExpertCreate = Loadable(lazy(() => import('../pages/dashboard/ExpertCreate')));
const ExpertEdit = Loadable(lazy(() => import('../pages/dashboard/ExpertEdit')));
const Orders = Loadable(lazy(() => import('../pages/dashboard/Orders')));
const Letters = Loadable(lazy(() => import('../pages/dashboard/Letters')));
const LetterEdit = Loadable(lazy(() => import('../pages/dashboard/LetterEdit')));
const LetterCreate = Loadable(lazy(() => import('../pages/dashboard/LetterCreate')));
const LetterDetails = Loadable(lazy(() => import('../pages/dashboard/LetterDetails')));
const Sms = Loadable(lazy(() => import('../pages/dashboard/Sms')));
const SmsEdit = Loadable(lazy(() => import('../pages/dashboard/SmsEdit')));
const SmsDetails = Loadable(lazy(() => import('../pages/dashboard/SmsDetails')));
const NotificationsList = Loadable(lazy(() => import('../pages/dashboard/NotificationsList')));
