// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    logout: path(ROOTS_AUTH, '/logout'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    register: path(ROOTS_AUTH, '/register'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page404: '/404',
    page500: '/500',
    components: '/components',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/app'),
    },
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        list: path(ROOTS_DASHBOARD, '/user/list'),
        newUser: path(ROOTS_DASHBOARD, '/user/new'),
        editUser: path(ROOTS_DASHBOARD, '/user/edit/:id'),
        access: path(ROOTS_DASHBOARD, '/user/:id/access'),
    },
    inquiries: {
        root: path(ROOTS_DASHBOARD, '/inquiries'),
        newInquiry: path(ROOTS_DASHBOARD, '/inquiries/new'),
        editById: path(ROOTS_DASHBOARD, `/inquiries/:id/edit`),
        inquiryDetails: path(ROOTS_DASHBOARD, `/inquiries/:id/details`),
        inquiryFields: path(ROOTS_DASHBOARD, `/inquiries/:id/fields`),
        inquiryFinance: path(ROOTS_DASHBOARD, `/inquiries/:id/finance`),
        inquiryOrders: path(ROOTS_DASHBOARD, `/inquiries/:id/orders`),
    },
    companies: {
        root: path(ROOTS_DASHBOARD, '/companies'),
        newCompany: path(ROOTS_DASHBOARD, '/companies/new'),
        editById: path(ROOTS_DASHBOARD, `/companies/:id/edit`),
        companyDetails: path(ROOTS_DASHBOARD, `/companies/:id/details`),
    },
    experts: {
        root: path(ROOTS_DASHBOARD, '/experts'),
        newExpert: path(ROOTS_DASHBOARD, '/experts/new'),
        editById: path(ROOTS_DASHBOARD, `/experts/:id/edit`),
        expertDetails: path(ROOTS_DASHBOARD, `/experts/:id/details`),
    },
    orders: {
        root: path(ROOTS_DASHBOARD, '/orders'),
        newOrder: path(ROOTS_DASHBOARD, '/order/new'),
        editById: path(ROOTS_DASHBOARD, `/order/:id/edit`),
        orderDetails: path(ROOTS_DASHBOARD, `/order/:id/details`),
    },
    letters: {
        root: path(ROOTS_DASHBOARD, '/letters'),
        newLetter: path(ROOTS_DASHBOARD, '/letters/new'),
        editById: path(ROOTS_DASHBOARD, `/letters/:id/edit`),
    },
    notifications: {
        root: path(ROOTS_DASHBOARD, '/notifications')
    },
    sms: {
        root: path(ROOTS_DASHBOARD, '/sms'),
        editById: path(ROOTS_DASHBOARD, `/sms/:id/edit`),
    },
};
