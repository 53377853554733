import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import LogoIric from '../../../layouts/Logo-Iric';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import GuestGuard from '../../../guards/GuestGuard';

// ----------------------------------------------------------------------

export default function LoginForm() {
    const { login } = useAuth();

    const isMountedRef = useIsMountedRef();

    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const defaultValues = {
        email: '',
        password: '',
        remember: true,
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        try {
            await login(data.email, data.password);
        } catch (error) {
            reset();
            if (isMountedRef.current) {
                setError('afterSubmit', { message: error.error });
            }
        }
    };

    return (
        <GuestGuard>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <LogoIric />
                <Stack sx={{ textAlign: 'center', marginBottom: 4 }}>
                    <Typography variant={'h6'} component="span">
                        سیستم مدیریت استعلام
                    </Typography>
                </Stack>
                <Stack spacing={3}>
                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                    <RHFTextField name="email" label="ایمیل" />

                    <RHFTextField
                        name="password"
                        label="رمز عبور"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    <RHFCheckbox name="remember" label="مرا به خاطر بسپار" />
                </Stack>

                <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
                    ورود
                </LoadingButton>

                <Stack sx={{ textAlign: 'center', marginTop: 6 }}>
                    <Typography variant={'caption'} component="span">
                        Powered by:{' '}
                        <Typography variant={'subtitle2'} component="span">
                            Saina
                        </Typography>{' '}
                        Business Development Co. All Rights reserved
                    </Typography>
                </Stack>
            </FormProvider>
        </GuestGuard>
    );
}
