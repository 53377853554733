import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Stack } from '@mui/material';
import mainLogo from './Logo-Iric.png';

export default function LogoIric({ disabledLink = true, sx }) {
    const logo = (
        <Box sx={{ width: 200, height: 150, ...sx }}>
            <img src={mainLogo} alt="fireSpot" />
        </Box>
    );

    if (disabledLink) {
        return <Stack sx={{flexDirection: 'row', justifyContent: 'center'}}>{logo}</Stack>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
